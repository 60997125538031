<template>
  <div class="login-container">
    <div class="shapes-container">
      <div
        class="shape"
        data-aos="fade-down-left"
        data-aos-duration="1500"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="fade-down"
        data-aos-duration="1000"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="fade-up-right"
        data-aos-duration="1000"
        data-aos-delay="200"
      ></div>
      <div
        class="shape"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
      ></div>
      <div
        class="shape"
        data-aos="fade-down-left"
        data-aos-duration="1000"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="fade-down-left"
        data-aos-duration="1000"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="zoom-in"
        data-aos-duration="1000"
        data-aos-delay="300"
      ></div>
      <div
        class="shape"
        data-aos="fade-down-right"
        data-aos-duration="500"
        data-aos-delay="200"
      ></div>
      <div
        class="shape"
        data-aos="fade-down-right"
        data-aos-duration="500"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="zoom-out"
        data-aos-duration="2000"
        data-aos-delay="500"
      ></div>
      <div
        class="shape"
        data-aos="fade-up-right"
        data-aos-duration="500"
        data-aos-delay="200"
      ></div>
      <div
        class="shape"
        data-aos="fade-down-left"
        data-aos-duration="500"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="fade-up"
        data-aos-duration="500"
        data-aos-delay="0"
      ></div>
      <div
        class="shape"
        data-aos="fade-down"
        data-aos-duration="500"
        data-aos-delay="0"
      ></div>
      <div
        class="shape"
        data-aos="fade-up-right"
        data-aos-duration="500"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="fade-down-left"
        data-aos-duration="500"
        data-aos-delay="0"
      ></div>
    </div>
    <section>
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="image align-self-center login-a">
              <b-img
                :src="require('@/assets/img/background/tbolivar-edeficio.jpg')"
                alt="login-img"
                class="login-img"
              ></b-img>
            </div>
            <div class="content text-center login-background">
              <div class="content text-center">
                <div class="logo">
                  <b-link href="/">
                    <b-img
                      :src="require('@/assets/img/logos/logo-01.png')"
                      alt="logo-tributos"
                    ></b-img>
                  </b-link>
                </div>
                <br />
                <div class="title-text">
                  <h3>Restablecer Contraseña</h3>
                </div>
                <br />
                <form @submit.prevent="resetPassword">
                  <b-form-input
                    type="email"
                    placeholder="Correo"
                    required
                    v-model="email"
                    autocomplete="email"
                    name="email"
                  ></b-form-input>
                  <b-form-input
                    type="password"
                    placeholder="Contraseña"
                    required
                    v-model="password"
                    name="password"
                  ></b-form-input>
                  <b-form-input
                    type="password"
                    placeholder="Cofirmar contraseña"
                    required
                    v-model="passwordConfirm"
                    name="password-confirm"
                  ></b-form-input>
                  <br />
                  <br />
                  <b-button type="submit" block variant="primary">
                    Enviar
                  </b-button>
                </form>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import { getError } from "@/utils/helpers";
import AuthService from "@/services/AuthService";

export default {
  name: "ResetPassword",
  data() {
    return {
      email: null,
      password: null,
      passwordConfirm: null,
      error: null,
      message: null,
    };
  },
  methods: {
    async resetPassword() {
      try {
        const payload = {
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirm,
          token: this.$route.query.token,
        };
        const response = await AuthService.resetPassword(payload);
        this.$toast.success(response.data.message, { timeout: 3000 });
        this.$router.push("/login");
      } catch (error) {
        const { email } = error.response.data.errors;
        if (email) {
          this.$toast.error(email[0], { timeout: 3000 });
        } else {
          this.$toast.error(getError(error), { timeout: 3000 });
        }
      }
    },
  },
};
</script>
